.skymod {
    background:#fff !important;
    background-size: 100% !important;
    background-repeat: no-repeat !important;
  }

  .gmw-divider {
    border: 0;
    height: 1px;
    background-image: linear-gradient(to right, rgb(0, 52, 105,100),#fff);
  }

  .forecast-browser-modal{
    min-width: 80vw;
  }

  .App {
    background:#fff !important;
  }
 